import { Alert, AlertIcon, Box, Button, Container, Heading, Icon, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { FcAssistant, FcDonate, FcInTransit } from 'react-icons/fc';
import Feature from "../components/home/Feature";
import PageLayout from "../components/layout/PageLayout";
import ArrowIcon from "../lib/ArrowIcon";
import GLink from "../lib/GLink";

type HomePageProps = {
  site:{
    buildTime: string;
  }
}

const HomePage: FC<PageProps<HomePageProps>> = ({data, path}) => {
  const {site} = data;

  return (<>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <PageLayout>
      <Alert status="warning" roundedBottom="sm" alignItems="center">
          <AlertIcon/>
          Aún estamos trabajando en este sitio. Algunas funcionalidades podrían no estar disponibles.
      </Alert>
      <Container my="10">
          <Stack
            as={Box}
            textAlign={'center'}
            spacing={{ base: 8, md: 14 }}
            py={{ base: 5, md: 10 }}
            >
            <Heading
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
              lineHeight={'110%'}>
              Información a su alcance <br />
              <Text as={'span'} color={'green.400'}>
                desde donde esté
              </Text>
            </Heading>
            <Text color={'gray.500'}>
              Póngase al tanto de los anuncios, proyectos y las finanzas del patronato y junta de agua; desde la comodidad de su hogar.
              Solicite accesso cuanto antes.
            </Text>
            <Stack
              direction={'column'}
              spacing={3}
              align={'center'}
              alignSelf={'center'}
              position={'relative'}>
              <GLink to="/app">
                  <Button
                  colorScheme={'green'}
                  bg={'green.400'}
                  rounded={'full'}
                  px={6}
                  _hover={{
                    bg: 'green.500',
                  }}>
                  Empezar ahora
                </Button>
              </GLink>
              <GLink to="/guide">
                <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
                Consultar más
              </Button>
              </GLink>
              <Box>
                <Icon
                  as={ArrowIcon}
                  color={useColorModeValue('gray.800', 'gray.300')}
                  w={71}
                  position={'absolute'}
                  right={-71}
                  top={'10px'}
                />
                <Text
                  fontSize={'lg'}
                  fontFamily={'Caveat'}
                  position={'absolute'}
                  right={'-125px'}
                  top={'-15px'}
                  transform={'rotate(10deg)'}>
                  Ingrese o cree su cuenta
                </Text>
              </Box>
            </Stack>
          </Stack>
      </Container>
      
      <Box my="10" py={4} px={4} bg={useColorModeValue("gray.200", "gray.800")} shadow="sm">
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          <Feature
            icon={<Icon as={FcAssistant} w={10} h={10} />}
            title={'Información 24/7'}
            text={
              'Encuentre información relevante a lo que sucede en la residencial en cualquier momento del día.'
            }
          />
          <Feature
            icon={<Icon as={FcDonate} w={10} h={10} />}
            title={'Contribuciones'}
            text={
              'Verifique su historial de contribuciones y las finanzas de la comunidad en tiempo real.'
            }
          />
          <Feature
            icon={<Icon as={FcInTransit} w={10} h={10} />}
            title={'Anuncie sus visitas'}
            text={
              'Registre las visitas de sus familiares o negocios con la anticipación que desee.'
            }
          />
        </SimpleGrid>
      </Box>
      
      <Container my="10">
          <Stack
            as={Box}
            textAlign={'center'}
            spacing={{ base: 4, md: 7 }}
            py={{ base: 5, md: 10 }}
            >
            <Heading
              fontWeight={600}
              fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}
              lineHeight={'110%'}>
              En línea pero manteniendo nuestra <br />
              <Text as={'span'} color={'green.400'}>
                privacidad
              </Text>
            </Heading>
            <Text color={'gray.500'}>
              Este producto está diseñado para que solamente nosotros los residentes tengamos acceso a la información.
            </Text>
            <Stack
              direction={'column'}
              // spacing={3}
              align={'center'}
              alignSelf={'center'}
              position={'relative'}>                
                <GLink to="/privacy">
                  <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
                  Leer la política de privacidad
                </Button>
                </GLink>
              </Stack>
          </Stack>
      </Container>
      
      <Text fontSize="smaller" color={useColorModeValue("gray.300", "gray.700")}>
        Path "{path}" was last updated on {" "}
        {new Date(site.buildTime).toLocaleString()}.
      </Text>
    </PageLayout>
  </>)
}
export default HomePage;

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`